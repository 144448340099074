export function UCLClimateActionUnitLOGO() {
  return (
    <svg
      width="200"
      height="15"
      viewBox="0 0 200 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_978_5995)">
        <path
          d="M1.91277 0.515625V9.15468C1.91277 10.1699 2.10448 11.013 2.48791 11.6851C3.05651 12.7003 4.01616 13.2079 5.36469 13.2079C6.98227 13.2079 8.08135 12.6589 8.66302 11.562C8.97673 10.9662 9.1325 10.1634 9.1325 9.15468V0.515625H11.0453V8.36496C11.0453 10.0838 10.8133 11.4062 10.3481 12.3321C9.49523 14.0194 7.88528 14.8625 5.51719 14.8625C3.1491 14.8625 1.54242 14.0194 0.696048 12.3321C0.232016 11.4062 0 10.0838 0 8.36496V0.515625H1.91277Z"
          fill="white"
        />
        <path
          d="M23.8224 1.53535C24.7875 2.46777 25.3235 3.52764 25.4302 4.71277H23.585C23.3791 3.81194 22.9695 3.09846 22.3563 2.57234C21.743 2.04622 20.8814 1.78262 19.7725 1.78262C18.4207 1.78262 17.3292 2.26626 16.4981 3.23462C15.667 4.20299 15.2509 5.68659 15.2509 7.6865C15.2509 9.32477 15.6267 10.6526 16.3772 11.6711C17.1277 12.6895 18.2475 13.1993 19.7365 13.1993C21.1069 13.1993 22.1515 12.6634 22.8671 11.5916C23.2473 11.0273 23.5305 10.2855 23.7179 9.36507H25.5631C25.3997 10.8367 24.8605 12.0708 23.9455 13.0664C22.8486 14.2646 21.3683 14.8648 19.5067 14.8648C17.9022 14.8648 16.5537 14.3725 15.4633 13.3889C14.0276 12.0883 13.3098 10.0796 13.3098 7.36298C13.3098 5.29989 13.849 3.60933 14.9274 2.28913C16.0929 0.854552 17.7018 0.136719 19.7518 0.136719C21.5012 0.136719 22.8584 0.602929 23.8235 1.53535H23.8224Z"
          fill="white"
        />
        <path
          d="M28.0098 0.515625H29.9029V12.8266H36.9146V14.4921H28.0098V0.515625Z"
          fill="white"
        />
        <path
          d="M50.6786 1.53426C51.6437 2.46668 52.1797 3.52655 52.2864 4.71168H50.4412C50.2353 3.81085 49.8257 3.09737 49.2125 2.57125C48.5992 2.04513 47.7376 1.78153 46.6287 1.78153C45.2769 1.78153 44.1855 2.26517 43.3543 3.23353C42.5232 4.2019 42.1071 5.6855 42.1071 7.68541C42.1071 9.32368 42.4829 10.6515 43.2334 11.67C43.9839 12.6885 45.1037 13.1982 46.5928 13.1982C47.9631 13.1982 49.0077 12.6623 49.7233 11.5905C50.1035 11.0262 50.3867 10.2844 50.5741 9.36399H52.4193C52.2559 10.8356 51.7167 12.0697 50.8017 13.0653C49.7048 14.2636 48.2245 14.8637 46.3629 14.8637C44.7584 14.8637 43.4099 14.3714 42.3195 13.3878C40.8839 12.0872 40.166 10.0786 40.166 7.36189C40.166 5.2988 40.7052 3.60825 41.7836 2.28804C42.9491 0.853463 44.558 0.136719 46.608 0.136719C48.3574 0.136719 49.7146 0.602929 50.6797 1.53535L50.6786 1.53426Z"
          fill="white"
        />
        <path d="M54.6851 0.515625H56.3974V14.4921H54.6851V0.515625Z" fill="white" />
        <path
          d="M58.9658 0.515625H60.7065V2.45672H58.9658V0.515625ZM58.9658 4.34988H60.7065V14.4921H58.9658V4.34988Z"
          fill="white"
        />
        <path
          d="M63.2947 4.30578H64.9885V5.75234C65.3948 5.25128 65.7619 4.88637 66.0919 4.65871C66.6562 4.27202 67.2967 4.07812 68.0134 4.07812C68.8249 4.07812 69.4785 4.27746 69.973 4.67723C70.2519 4.90598 70.5057 5.24147 70.7344 5.6859C71.1146 5.14017 71.5623 4.73605 72.0764 4.47244C72.5906 4.20884 73.1668 4.07812 73.8084 4.07812C75.1787 4.07812 76.1111 4.57266 76.6056 5.56172C76.8725 6.09438 77.0054 6.81112 77.0054 7.71195V14.496H75.2266V7.41785C75.2266 6.73923 75.0567 6.27302 74.7179 6.01922C74.3781 5.76542 73.9641 5.63906 73.4761 5.63906C72.8041 5.63906 72.2246 5.86454 71.7398 6.31441C71.254 6.76428 71.0122 7.51697 71.0122 8.56922V14.496H69.2715V7.84594C69.2715 7.15424 69.1888 6.651 69.0243 6.33293C68.7639 5.85691 68.2792 5.61945 67.569 5.61945C66.922 5.61945 66.3338 5.86999 65.8044 6.37105C65.275 6.87212 65.0103 7.77949 65.0103 9.09207V14.496H63.2979V4.30578H63.2947Z"
          fill="white"
        />
        <path
          d="M84.9866 8.32574C85.3798 8.27564 85.6434 8.11007 85.7763 7.8323C85.8526 7.6798 85.8907 7.45977 85.8907 7.17329C85.8907 6.58726 85.6837 6.16244 85.2687 5.89774C84.8537 5.63305 84.2612 5.50125 83.4889 5.50125C82.5967 5.50125 81.9628 5.74307 81.5902 6.2278C81.3811 6.49576 81.2449 6.89443 81.1818 7.42273H79.5838C79.6154 6.16353 80.0228 5.28666 80.8049 4.79431C81.587 4.30195 82.4943 4.05469 83.527 4.05469C84.7241 4.05469 85.6968 4.28344 86.4441 4.73984C87.1848 5.19625 87.5562 5.90646 87.5562 6.87156V12.7417C87.5562 12.9192 87.5922 13.0619 87.6651 13.1698C87.7381 13.2776 87.8917 13.331 88.127 13.331C88.2032 13.331 88.2893 13.3266 88.3841 13.3168C88.4788 13.307 88.5801 13.2928 88.688 13.2743V14.539C88.4211 14.6152 88.2185 14.6632 88.0791 14.6817C87.9396 14.7002 87.749 14.71 87.5083 14.71C86.9179 14.71 86.4898 14.5009 86.224 14.0826C86.0846 13.8604 85.9866 13.5466 85.9288 13.1403C85.5803 13.5968 85.0792 13.9933 84.4256 14.3298C83.7721 14.6653 83.052 14.8342 82.2656 14.8342C81.3201 14.8342 80.5489 14.5477 79.9487 13.9736C79.3485 13.3996 79.05 12.6829 79.05 11.8212C79.05 10.8779 79.3452 10.1459 79.9345 9.62634C80.5249 9.10675 81.2983 8.78651 82.2558 8.66669L84.9866 8.32465V8.32574ZM81.3811 12.9519C81.7427 13.2373 82.1708 13.3789 82.6654 13.3789C83.2677 13.3789 83.8516 13.2395 84.4158 12.9606C85.3668 12.4977 85.8428 11.7417 85.8428 10.6906V9.31372C85.6336 9.4477 85.3646 9.55881 85.0356 9.64813C84.7067 9.73745 84.3831 9.80062 84.0673 9.83875L83.0324 9.97164C82.4126 10.0544 81.9453 10.1841 81.6327 10.3605C81.1033 10.6579 80.8386 11.1328 80.8386 11.7842C80.8386 12.2776 81.0195 12.6665 81.3811 12.9519Z"
          fill="white"
        />
        <path
          d="M90.7052 1.46094H92.4371V4.30613H94.0645V5.70476H92.4371V12.3548C92.4371 12.7099 92.557 12.9485 92.7988 13.0683C92.9317 13.138 93.1539 13.1728 93.4643 13.1728C93.5471 13.1728 93.6354 13.1707 93.7312 13.1663C93.826 13.162 93.9371 13.1511 94.0645 13.1347V14.4952C93.8674 14.5519 93.6637 14.5933 93.4513 14.6183C93.2389 14.6434 93.009 14.6564 92.7618 14.6564C91.9622 14.6564 91.4209 14.4517 91.1344 14.0432C90.849 13.6347 90.7063 13.1031 90.7063 12.4496V5.70367H89.3262V4.30504H90.7063V1.46094H90.7052Z"
          fill="white"
        />
        <path
          d="M102.07 4.58291C102.744 4.92277 103.258 5.36174 103.611 5.90094C103.952 6.41508 104.178 7.01418 104.292 7.69933C104.392 8.16881 104.443 8.91715 104.443 9.94433H97.0317C97.0633 10.9813 97.3051 11.8124 97.7583 12.4388C98.2114 13.0651 98.9129 13.3788 99.8638 13.3788C100.752 13.3788 101.46 13.0825 101.988 12.49C102.284 12.1458 102.493 11.7482 102.616 11.295H104.3C104.256 11.6697 104.109 12.0859 103.858 12.5466C103.608 13.0063 103.328 13.3821 103.019 13.674C102.503 14.1816 101.864 14.5237 101.102 14.7012C100.693 14.8025 100.231 14.8537 99.7146 14.8537C98.4543 14.8537 97.3857 14.3919 96.51 13.4692C95.6342 12.5466 95.1963 11.2536 95.1963 9.5925C95.1963 7.93135 95.6374 6.62748 96.5198 5.60574C97.4021 4.584 98.5556 4.07422 99.9804 4.07422C100.699 4.07422 101.395 4.24415 102.07 4.58291ZM102.683 8.58383C102.613 7.84203 102.453 7.24837 102.201 6.80504C101.736 5.98045 100.96 5.56871 99.8715 5.56871C99.0915 5.56871 98.438 5.85301 97.9097 6.42052C97.3814 6.98804 97.1014 7.70914 97.0698 8.58492H102.683V8.58383Z"
          fill="white"
        />
        <path
          d="M116.226 0.515625H118.366L123.437 14.4921H121.363L119.946 10.306H114.418L112.905 14.4921H110.964L116.226 0.515625ZM119.337 8.76472L117.215 2.58961L114.96 8.76472H119.337Z"
          fill="white"
        />
        <path
          d="M131.662 4.84547C132.382 5.40318 132.814 6.36501 132.96 7.72769H131.295C131.194 7.10027 130.962 6.57851 130.6 6.1624C130.238 5.74739 129.658 5.53934 128.859 5.53934C127.768 5.53934 126.988 6.0709 126.518 7.13622C126.215 7.82682 126.062 8.67863 126.062 9.69275C126.062 10.7069 126.278 11.5707 126.709 12.2678C127.14 12.9649 127.819 13.3124 128.745 13.3124C129.455 13.3124 130.018 13.0957 130.433 12.6632C130.848 12.2308 131.136 11.6382 131.294 10.8866H132.959C132.769 12.2373 132.294 13.2253 131.532 13.8505C130.771 14.4758 129.797 14.7873 128.612 14.7873C127.28 14.7873 126.218 14.3004 125.425 13.3266C124.632 12.3528 124.235 11.1371 124.235 9.6775C124.235 7.88891 124.67 6.49681 125.539 5.50121C126.408 4.50561 127.515 4.00781 128.859 4.00781C130.007 4.00781 130.941 4.28667 131.661 4.84547H131.662Z"
          fill="white"
        />
        <path
          d="M135.016 1.46094H136.748V4.30613H138.375V5.70476H136.748V12.3548C136.748 12.7099 136.868 12.9485 137.11 13.0683C137.242 13.138 137.465 13.1728 137.775 13.1728C137.858 13.1728 137.946 13.1707 138.042 13.1663C138.137 13.162 138.248 13.1511 138.375 13.1347V14.4952C138.178 14.5519 137.974 14.5933 137.762 14.6183C137.55 14.6434 137.32 14.6564 137.073 14.6564C136.273 14.6564 135.732 14.4517 135.445 14.0432C135.16 13.6347 135.017 13.1031 135.017 12.4496V5.70367H133.637V4.30504H135.017V1.46094H135.016Z"
          fill="white"
        />
        <path
          d="M140.086 0.515625H141.827V2.45672H140.086V0.515625ZM140.086 4.34988H141.827V14.4921H140.086V4.34988Z"
          fill="white"
        />
        <path
          d="M151.798 5.32212C152.699 6.19681 153.149 7.48324 153.149 9.18143C153.149 10.8796 152.753 12.1791 151.959 13.2499C151.164 14.3206 149.934 14.8566 148.265 14.8566C146.873 14.8566 145.767 14.3827 144.949 13.4361C144.13 12.4885 143.721 11.2162 143.721 9.61932C143.721 7.90806 144.153 6.54538 145.015 5.53235C145.878 4.51823 147.037 4.01172 148.492 4.01172C149.796 4.01172 150.899 4.44961 151.8 5.32321L151.798 5.32212ZM150.735 12.0942C151.152 11.2358 151.361 10.2805 151.361 9.22936C151.361 8.27951 151.21 7.50612 150.908 6.91028C150.431 5.97242 149.61 5.50403 148.443 5.50403C147.409 5.50403 146.656 5.9027 146.185 6.70114C145.715 7.49958 145.48 8.4625 145.48 9.58991C145.48 10.7173 145.715 11.5757 146.185 12.2978C146.656 13.02 147.402 13.3806 148.425 13.3806C149.548 13.3806 150.317 12.9514 150.734 12.0931L150.735 12.0942Z"
          fill="white"
        />
        <path
          d="M155.252 4.30578H156.88V5.75234C157.361 5.15651 157.872 4.72842 158.411 4.46809C158.951 4.20775 159.55 4.07812 160.21 4.07812C161.656 4.07812 162.632 4.58246 163.14 5.59113C163.419 6.14339 163.558 6.93312 163.558 7.96031V14.496H161.818V8.07359C161.818 7.45162 161.725 6.95055 161.542 6.57039C161.238 5.93643 160.686 5.61945 159.886 5.61945C159.48 5.61945 159.148 5.66085 158.887 5.74254C158.418 5.88197 158.006 6.16082 157.651 6.58019C157.366 6.91678 157.18 7.26426 157.094 7.62154C157.008 7.97992 156.966 8.49188 156.966 9.15851V14.496H155.254V4.30578H155.252Z"
          fill="white"
        />
        <path
          d="M169.337 0.515625V9.15468C169.337 10.1699 169.529 11.013 169.912 11.6851C170.481 12.7003 171.44 13.2079 172.789 13.2079C174.406 13.2079 175.506 12.6589 176.087 11.562C176.401 10.9662 176.557 10.1634 176.557 9.15468V0.515625H178.469V8.36496C178.469 10.0838 178.237 11.4062 177.772 12.3321C176.919 14.0194 175.309 14.8625 172.941 14.8625C170.573 14.8625 168.967 14.0194 168.12 12.3321C167.655 11.4062 167.423 10.0838 167.423 8.36496V0.515625H169.336H169.337Z"
          fill="white"
        />
        <path
          d="M181.134 4.30578H182.761V5.75234C183.243 5.15651 183.754 4.72842 184.293 4.46809C184.832 4.20775 185.431 4.07812 186.091 4.07812C187.538 4.07812 188.514 4.58246 189.021 5.59113C189.3 6.14339 189.44 6.93312 189.44 7.96031V14.496H187.699V8.07359C187.699 7.45162 187.606 6.95055 187.423 6.57039C187.119 5.93643 186.567 5.61945 185.768 5.61945C185.361 5.61945 185.029 5.66085 184.769 5.74254C184.299 5.88197 183.887 6.16082 183.532 6.58019C183.247 6.91678 183.062 7.26426 182.976 7.62154C182.89 7.97992 182.847 8.49188 182.847 9.15851V14.496H181.135V4.30578H181.134Z"
          fill="white"
        />
        <path
          d="M191.971 0.515625H193.712V2.45672H191.971V0.515625ZM191.971 4.34988H193.712V14.4921H191.971V4.34988Z"
          fill="white"
        />
        <path
          d="M196.642 1.46094H198.374V4.30613H200.001V5.70476H198.374V12.3548C198.374 12.7099 198.493 12.9485 198.735 13.0683C198.868 13.138 199.09 13.1728 199.401 13.1728C199.484 13.1728 199.572 13.1707 199.668 13.1663C199.763 13.162 199.874 13.1511 200.001 13.1347V14.4952C199.804 14.5519 199.6 14.5933 199.388 14.6183C199.175 14.6434 198.946 14.6564 198.698 14.6564C197.899 14.6564 197.357 14.4517 197.071 14.0432C196.786 13.6347 196.643 13.1031 196.643 12.4496V5.70367H195.263V4.30504H196.643V1.46094H196.642Z"
          fill="white"
        />
        <path
          d="M107.665 14.3091C107.665 14.5172 107.861 14.6413 108.008 14.5259C108.008 14.5259 113.709 0.62997 113.715 0.617988C113.818 0.456775 113.684 0.244366 113.493 0.242188L107.884 0.262884C107.66 0.250902 107.587 0.292294 107.542 0.480739L107.664 14.3102L107.665 14.3091Z"
          fill="#90BACA"
          stroke="#90BACA"
          strokeWidth="1.36"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_978_5995">
          <rect width="200" height="14.7281" fill="white" transform="translate(0 0.136719)" />
        </clipPath>
      </defs>
    </svg>
  )
}
