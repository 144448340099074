export function Data4ChangeLOGO() {
  return (
    <svg
      width="141"
      height="27"
      viewBox="0 0 141 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_976_5989)">
        <path
          d="M0.95254 13.9638L17.194 22.038L16.7375 22.951L1.33754 15.2976L0.95254 13.9638ZM1.92054 17.528L15.951 24.5035L16.413 23.6124L1.56304 16.2215L1.92054 17.528ZM2.53104 19.7858L15.181 26.0724L15.643 25.1595L2.17354 18.4519L2.53104 19.7858ZM31.3235 26.067L30.174 25.8866L22.0175 2.10658L23.1505 2.33618L29.47 20.6714L41.438 12.4714L29.415 6.50178L29.8715 5.57245L46.6905 13.9364L47.048 15.2539L31.3235 26.067ZM46.1295 14.8166L45.4805 14.494L30.7955 24.5308L31.0155 25.165L46.1295 14.8166ZM30.493 23.6507L44.562 14.0403L43.9185 13.7178L30.284 23.0658L30.493 23.6507ZM29.8 21.5515L30.009 22.1638L43 13.2586L42.3565 12.9415L29.8 21.5515ZM46.1075 11.7115L30.7075 4.05818L30.251 4.97111L46.4925 13.0508L46.1075 11.7115ZM45.497 9.47018L31.45 2.48378L30.977 3.38578L45.827 10.7712L45.497 9.47018ZM44.859 7.21245L32.209 0.925781L31.747 1.83871L45.2 8.53538L44.859 7.21245ZM20.021 1.71298L28.205 25.5695L29.36 25.7554L21.11 1.93711L20.021 1.71298ZM18.008 1.31391L26.2415 25.2524L27.402 25.4492L19.185 1.54898L18.008 1.31391ZM17.238 1.16631L25.4385 25.1322L24.3 24.9463L17.9255 6.31591L5.95204 14.5159L17.975 20.4855L17.5185 21.4258L0.699541 13.0344L0.342041 11.717L16.0885 0.936715L17.238 1.16631ZM16.908 3.35298L2.85004 12.9524L3.49354 13.2695L17.117 3.93245L16.908 3.35298ZM1.26054 12.1707L1.90954 12.4932L16.6 2.45098L16.38 1.81685L1.26054 12.1707ZM17.623 5.43578L17.414 4.82351L4.39004 13.7287L5.03354 14.0512L17.623 5.43578Z"
          fill="white"
        />
        <path
          d="M55.5291 8.3543H57.8116C58.2632 8.33611 58.7139 8.40882 59.1366 8.56807C59.5592 8.72732 59.9452 8.96982 60.2712 9.28101C60.5972 9.5922 60.8566 9.96567 61.0338 10.379C61.211 10.7923 61.3024 11.237 61.3024 11.6862C61.3024 12.1355 61.211 12.5802 61.0338 12.9935C60.8566 13.4068 60.5972 13.7803 60.2712 14.0914C59.9452 14.4026 59.5592 14.6451 59.1366 14.8044C58.7139 14.9636 58.2632 15.0363 57.8116 15.0182H55.5291V8.3543ZM57.9491 13.7554C58.4771 13.7554 58.9835 13.5469 59.3569 13.1757C59.7303 12.8046 59.9401 12.3013 59.9401 11.7764C59.9401 11.2516 59.7303 10.7482 59.3569 10.3771C58.9835 10.006 58.4771 9.7975 57.9491 9.7975H57.1571V13.7718L57.9491 13.7554Z"
          fill="white"
        />
        <path
          d="M65.5994 8.35547H66.9579L69.8289 15.0193H68.2999L67.6949 13.5761H64.8514L64.2464 15.0193H62.7229L65.5994 8.35547ZM67.1174 12.3188L66.2759 10.3071L65.4344 12.3188H67.1174Z"
          fill="white"
        />
        <path
          d="M72.5514 9.79867H70.7419V8.35547H75.6314V9.79867H73.9814V15.0193H72.5294L72.5514 9.79867Z"
          fill="white"
        />
        <path
          d="M79.5421 8.35547H80.9006L83.7716 15.0193H82.2481L81.6376 13.5761H78.7996L78.2001 15.0193H76.6821L79.5421 8.35547ZM81.0601 12.3188L80.2186 10.3071L79.3771 12.3188H81.0601Z"
          fill="white"
        />
        <path
          d="M88.4904 13.5761H85.2344V12.4336L88.6499 8.35547H89.9369V12.3188H90.8499V13.5761H89.9479V15.0193H88.4959L88.4904 13.5761ZM88.4904 10.4765L86.9504 12.3188H88.4904V10.4765Z"
          fill="white"
        />
        <path
          d="M92.9236 11.7347C92.9354 10.9636 93.2031 10.218 93.6852 9.61376C94.1673 9.00954 94.8367 8.58056 95.5895 8.39353C96.3422 8.20649 97.1359 8.27188 97.8474 8.57952C98.5589 8.88716 99.1481 9.41981 99.5236 10.0947L98.2036 10.8108C97.9979 10.4092 97.6621 10.088 97.2503 9.89925C96.8386 9.71045 96.3748 9.66495 95.9339 9.77009C95.493 9.87523 95.1005 10.1249 94.8197 10.4788C94.539 10.8327 94.3863 11.2703 94.3863 11.721C94.3863 12.1717 94.539 12.6093 94.8197 12.9632C95.1005 13.3171 95.493 13.5668 95.9339 13.6719C96.3748 13.7771 96.8386 13.7316 97.2503 13.5428C97.6621 13.354 97.9979 13.0329 98.2036 12.6312L99.5236 13.3473C99.1481 14.0222 98.5589 14.5549 97.8474 14.8625C97.1359 15.1701 96.3422 15.2355 95.5895 15.0485C94.8367 14.8615 94.1673 14.4325 93.6852 13.8283C93.2031 13.224 92.9354 12.4784 92.9236 11.7073V11.7347Z"
          fill="white"
        />
        <path
          d="M101.894 8.35547H103.346V11.0396H106.063V8.35547H107.51V15.0193H106.063V12.3188H103.346V15.0193H101.894V8.35547Z"
          fill="white"
        />
        <path
          d="M112.531 8.35547H113.889L116.755 15.0193H115.237L114.626 13.5761H111.788L111.2 15.0193H109.66L112.531 8.35547ZM114.049 12.3188L113.207 10.3071L112.366 12.3188H114.049Z"
          fill="white"
        />
        <path
          d="M123.613 15.0193L120.368 10.9029V15.0193H118.9V8.35547H120.187L123.426 12.4664V8.35547H124.878V15.0193H123.613Z"
          fill="white"
        />
        <path
          d="M127.447 11.7626C127.439 11.3058 127.524 10.852 127.697 10.4286C127.869 10.0052 128.127 9.621 128.453 9.29917C128.78 8.97734 129.168 8.72455 129.595 8.55603C130.023 8.38751 130.48 8.30676 130.94 8.31864C131.449 8.30051 131.957 8.39282 132.427 8.58917C132.897 8.78552 133.318 9.08121 133.662 9.45571L132.617 10.4944C132.405 10.2626 132.146 10.0787 131.857 9.95519C131.567 9.83164 131.254 9.7713 130.94 9.77824C130.406 9.79057 129.899 10.0132 129.53 10.3971C129.161 10.7811 128.961 11.2949 128.973 11.8255C128.986 12.3562 129.21 12.8602 129.596 13.2267C129.982 13.5932 130.499 13.7922 131.033 13.7798C131.487 13.8127 131.939 13.7017 132.326 13.4628V12.5006H131.055V11.041H133.75V14.2117C133.389 14.5515 132.961 14.8141 132.494 14.9833C132.027 15.1525 131.53 15.2248 131.033 15.1957C130.569 15.2118 130.106 15.135 129.672 14.9699C129.239 14.8047 128.843 14.5546 128.508 14.2343C128.174 13.9141 127.907 13.5302 127.725 13.1056C127.543 12.681 127.448 12.2243 127.447 11.7626Z"
          fill="white"
        />
        <path
          d="M136.5 8.35547H140.669V9.79867H137.952V11.0396H140.124V12.4828H137.952V13.7729H140.669V15.0357H136.5V8.35547Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_976_5989">
          <rect width="141" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
